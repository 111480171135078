import './global.css';
import { Routess } from './router/Router';
import { Header } from './Components/Header/Header';

function App() {

  return (
    <>
      <Routess/>      
    </>
  );
}

export default App;